import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AGE_THRESHOLD } from "../../../config/constants";
import {
	AGED_FILTERING_KEY,
	FILTERING_AGE_VALUE,
} from "../../../config/site/insuranceranked";
import { AgeToggleFilterCalenderIcon } from "../../client/assets/icons/insuranceranked/AgeToggleFilterCalenderIcon";
import { AgeToggleFilterSelectedIcon } from "../../client/assets/icons/insuranceranked/AgeToggleFilterSelectedIcon";
import { getIsNotModalFiltered, getModalSelection } from "../../selectors";
import {
	deleteFilteringProperty,
	setFilteringState,
	setFilteringStateWithoutTransition,
} from "../../store/filtering/actions";
import { BaseSegmentedToggle } from "../BaseComponents/BaseSegmentedToggle";

const useFilterToggle = () => {
	const [value, setValue] = useState(false);
	const [valueChanged, setValueChanged] = useState(false);

	const dispatch = useDispatch();
	const modalSelectedOption = useSelector((state) =>
		getModalSelection(state, { filteringKey: AGED_FILTERING_KEY }),
	);
	const isModalFiltered = useSelector((state) => !getIsNotModalFiltered(state));

	const handleChange = useCallback(
		(newValue) => {
			setValue(newValue);
			setValueChanged(true);

			if (newValue) {
				dispatch(
					setFilteringStateWithoutTransition({
						[AGE_THRESHOLD]: FILTERING_AGE_VALUE,
					}),
				);
			} else {
				dispatch(deleteFilteringProperty(AGE_THRESHOLD));
			}

			dispatch(
				setFilteringState({
					[AGED_FILTERING_KEY]: newValue,
					isNotUserFiltered: false,
				}),
			);
		},
		[dispatch],
	);

	// use modalValue if modalFiltered and user didn't make a change in the UI
	const segmentedToggleValue = useMemo(
		() => (isModalFiltered && !valueChanged ? modalSelectedOption : value),
		[isModalFiltered, valueChanged, modalSelectedOption, value],
	);

	return {
		handleChange,
		segmentedToggleValue,
	};
};

export const LifeInsuranceFilter = () => {
	const { handleChange, segmentedToggleValue } = useFilterToggle();

	const renderRadioContent = (value, label) => (
		<>
			<span
				className={`tw-mb-0 tw-inline-flex tw-size-4 tw-items-center tw-justify-center tw-rounded-full tw-transition-all tw-ease-in-out ${
					segmentedToggleValue === (value === "true")
						? "tw-bg-white"
						: "tw-ring-2 tw-ring-inset tw-ring-current"
				}`}
			>
				<AgeToggleFilterSelectedIcon
					className={`tw-text-[8px] tw-text-[#2299DC] tw-transition-all tw-ease-in-out ${
						segmentedToggleValue === (value === "true")
							? "tw-opacity-100"
							: "tw-opacity-0"
					}`}
				/>
			</span>
			<span>{label}</span>
		</>
	);

	return (
		<div className="tw-mx-auto tw-mt-4 tw-px-[15px] sm:tw-max-w-[720px] desktop:tw-max-w-[960px] lg:tw-mt-6 lg2:tw-max-w-[1140px]">
			<div className="tw-w-full tw-rounded-[3px] tw-bg-[#F5F8FA] tw-p-3 lg:tw-p-4">
				<div className="tw-mx-auto tw-flex tw-w-full tw-max-w-[320px] tw-flex-col tw-items-center tw-gap-4 sm:tw-max-w-fit sm:tw-flex-row md:tw-mx-0">
					<div className="tw-flex tw-items-center tw-gap-3">
						<AgeToggleFilterCalenderIcon className="tw-text-[32px] tw-text-[#2299DC]" />
						<span className="tw-text-xl tw-font-bold tw-leading-8 tw-tracking-[-0.2px] tw-text-[#232227]">
							{`Are you ${FILTERING_AGE_VALUE} or Older?`}
						</span>
					</div>
					<BaseSegmentedToggle
						options={[
							{
								value: "true",
								label: renderRadioContent("true", "Yes"),
							},
							{
								value: "false",
								label: renderRadioContent("false", "No"),
							},
						]}
						selected={String(segmentedToggleValue)}
						onChange={(value) => handleChange(value === "true")}
						styles={segmentedToggleStyles}
						aria-label={`Age filter toggle, I'm ${segmentedToggleValue ? `${FILTERING_AGE_VALUE} or older` : `under ${FILTERING_AGE_VALUE}`}`}
					/>
				</div>
			</div>
		</div>
	);
};

const segmentedToggleStyles = {
	radioGroup:
		"tw-inline-flex tw-w-full tw-gap-1 tw-overflow-hidden tw-rounded-full tw-p-1.5 tw-ring-1 tw-ring-inset tw-ring-[#D4D4D8] sm:tw-w-auto sm:tw-justify-center",
	radio:
		"tw-inline-flex tw-flex-1 tw-items-center tw-justify-center tw-cursor-pointer tw-select-none tw-appearance-none tw-gap-1 tw-whitespace-nowrap tw-rounded-full tw-border-none tw-py-2 tw-pl-2 tw-pr-3 tw-mb-0 tw-align-middle tw-text-base tw-font-semibold tw-leading-4 tw-outline-none tw-transition-all tw-ease-in-out",
	selected: "tw-bg-[#2299DC] tw-text-white hover:tw-bg-blue-600",
	default: "tw-bg-transparent tw-text-[#535B62] hover:tw-bg-gray-300",
};
